.artist-profile {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all content horizontally */
  justify-content: center; /* Center all content vertically */
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.artist-embed {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.artist-profile h1 {
  color: #ffffff;
  padding-top: 100px;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

.artist-photo {
  max-width: 300px;
  margin-bottom: 20px;
  display: flex; /* Use flexbox to center the image */
  justify-content: center;
}

.artist-photo img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.social-links, .streaming-links {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center; /* Center social and streaming links */
}

.social-link, .streaming-link {
  font-size: 64px;
  color: #fff;
  padding: 10px;
}

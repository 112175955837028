@keyframes ease-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.App {
  text-align: center;
  background-color: black;
}

.App-logo-container {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 150px;
  animation: ease-in 0.6s ease-in;
}

.App-logo {
  width: 50%;
  height: auto;
}

.App-title {
  padding-bottom: 5px;
  text-align: center;
  animation: ease-in 0.6s ease-in;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
}

.Event-title {
  padding-bottom: 25px;
  text-align: center;
  animation: ease-in 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
}

.Home-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  animation: ease-in 0.6s ease-in;
}

.Event-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  animation: ease-in 0.6s ease-in;
  width: 100%;
  max-width: 1200px;
}

.Event-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Event-image {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.Event-flyer {
  width: 100%;
  height: auto;
  animation: ease-in 0.6s ease-in;
}

.Event-details {
  text-align: center;
  animation: ease-in 0.6s ease-in;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  background-color: #fff;
  color: black;
  border: 1px solid #000;
  border-radius: 8px;
  gap: 10px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  align-self: center;
}

.custom-button:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
}

.button-icon {
  font-size: 18pt;
  padding-right: 5px;
  text-align: center;
}

.page-title h1 {
  color: #ffffff;
  padding-top: 100px;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

.page-title p {
  color: #ffffff;
  padding-top: 100px;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

body {
  color: white;
}

.pageText {
  color: white;
  text-align: center;
  justify-content: center;
  margin: 15px;
}

.Tickets-main {
  margin: 2rem;
  margin-bottom: 4rem;
  scale: 100%;
  overflow: hidden;
  max-height: 250rem;
}

.ticket-widget {
  filter: invert(1) saturate(0%) brightness(150%);
  background-color: black;
  width: 100%;
  height: 145rem;
}

.Tickets {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.event-card {
  background-color: #000;
  border: 1px solid #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  margin-top: 75px;
  display: flex;
  flex-direction: column;
}

.event-card-content {
  padding: 2rem;
}

.event-title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
}

.event-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.detail-column {
  flex-basis: calc(50% - 10px);
  text-align: center;
}

.detail-column .fa-icon {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.detail-column h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #fff;
}

.detail-column p {
  font-size: 1rem;
  color: #ddd;
}

.event-description {
  text-align: center;
  line-height: 1.6;
  font-size: 1rem;
  color: #ddd;
  margin-bottom: 20px;
}

.event-flyer {
  width: 100%;
  max-height: 400px;
  position: relative;
  overflow: hidden;
}

.event-flyer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.payment-link {
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: center;
}

.payment-link a {
  color: #fff;
  text-decoration: underline;
}

.payment-link a:hover {
  color: #ddd;
}

.widget-container {
  margin-top: 2rem;
}

.Terms-main {
  font-family: Arial, sans-serif;
  color: #fff;
  line-height: 1.6;
  padding: 20px;
}

.Terms-main h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.Terms-main ul {
  list-style-type: disc;
  margin-left: 20px;
}

.Terms-main li {
  margin-bottom: 10px;
}

.Rules-main {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 20px;
}

.Rules-main h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.Rules-main ul {
  list-style-type: disc;
  margin-left: 20px;
}

.Rules-main li {
  margin-bottom: 10px;
}

.insta-embed {
  margin-top: 2rem;
  width: 100%;
  max-width: 540px;
}

/* Media query for desktop view */
@media (min-width: 1024px) {
  .Event-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .Event-image {
    width: 45%;
    max-width: none;
    margin: 0;
  }

  .Event-details {
    width: 50%;
    text-align: left;
    align-items: flex-start;
  }

  .custom-button {
    align-self: center;
    margin-top: 30px;
  }

  .Event-section {
    align-items: flex-start;
  }

  .insta-embed {
    align-self: center;
  }

  .event-card {
    flex-direction: row;
    align-items: stretch;
  }

  .event-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .event-flyer {
    width: 40%;
    max-height: none;
  }

  .event-flyer img {
    object-fit: cover;
    height: 100%;
  }

  .detail-column {
    flex-basis: calc(25% - 20px);
  }

  .event-description {
    text-align: left;
    font-size: 1.1rem;
  }
}

/* Additional media query for very large screens */
@media (min-width: 1440px) {
  .Event-section {
    max-width: 1400px;
  }
}



.Locations {
  text-align: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.Locations-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-bottom: 100px;
}

.App-subtitle {
  font-size: 1rem;
  margin-bottom: 60px;
  color: #888;
}

.arrival-instructions {
  width: 100%;
  max-width: 500px;
  margin-bottom: 4rem;
}

.arrival-instructions ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-top: 2rem;
}

.arrival-instructions li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.arrival-instructions li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #fff;
}

.coordinates {
  
  gap: 10px;
  font-size: 1.2rem;
  border-radius: 5px;
}

.location-icon {

  font-size: 3rem;
  margin-bottom: 2rem;
}

.Locations .App-logo-container {
  max-width: 20%;
  margin: 0 auto;
}

.Locations .App-logo {
  width: 100%;
  height: auto;
}




@media (max-width: 768px) {
  .Locations {
    padding: 10px;
  }

 
}